import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";

class Category extends Component {

    settings = this.props.Settings;

    state = {
        categories: [],
        load: false,
        back: false
    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e.target.value);
        console.log(e.target.value);
        console.log(this.props.Language);
    }

    headCover = {'backgroundImage':`url('/assets/cover.jpg')`, 'backgroundSize':'cover'}
    categoryCover = {'backgroundSize': 'cover', 'backgroundPosition': 'center'}

    render() {
        return (

            <>
                { this.state.back && <Navigate to="/" replace={true} /> }
                <div className='category'>

                    <div className="head" style={this.headCover}>
                            <Link to={"/"}>
                                {/* onClick={this.back} */}
                                <i  className="backward fa-solid fa-arrow-left"></i>
                            </Link>
                            
                            <select name="language"
                                value={this.props.Language}
                                onChange={this.handleChange}  >
                                <option value="krd">کوردی</option>
                                <option value="ar">عربی</option>
                                <option value="en">English</option>
                            </select>                          
                        <img src={this.settings.logo} alt="" />
                    </div>
                    <div className="info">
                        <b>{this.settings.name}</b>
                        <div className='address'>
                            <div>
                                <i className="fa-solid fa-map-location-dot"></i><span>{this.settings.address}</span>
                            </div>
                            <div className='phone'>
                                <i className="fa-solid fa-phone"></i> 
                                <div>
                                    <span>{this.settings.phone}</span>
                                    <span>{this.settings.phone_2}</span>
                                </div>
                            </div>
                        </div>

        
                    </div>
                    

                    

                    <div className="category-list">


                        <div className="category-item" >
                            <Link to={"/menu/restaurant"} style={ { backgroundImage :`url(${process.env.PUBLIC_URL + 'assets/Restaurant.jpg'})`, ...this.categoryCover} }>
                                <h2> 
                                    {
                                        this.props.Language == "en" ? 'Restutant' : this.props.Language == "krd" ? 'خوارنگەهـ' : 'مطعم'
                                    }
                                </h2>
                            </Link>
                        </div>

                        <div className="category-item" >
                            <Link to={"/menu/coffee"} style={ { backgroundImage :`url(${process.env.PUBLIC_URL + 'assets/Coffe.jpg'})`, ...this.categoryCover} }>
                                <h2> 
                                    {
                                        this.props.Language == "en" ? 'Coffe shop' : this.props.Language == "krd" ? 'کافی' : 'کافی'
                                    }
                                </h2>
                            </Link>
                        </div>

                    </div>

                </div>
            </>
        );
    }
}

export default Category;